export interface SeverityLevelDTO {
  id: number;
  title_en: string;
  title_ar: string;
  color_code: string;
  type: string;
  status: string;
}
export interface SeverityLevelDetailsDTO {
  id: number;
  title: string;
  color_code: string;
  type: string;
  status: string;
}

export function convertResponseToSeverityLevelDetailsDTO(
  response: any,
  lang: string
): SeverityLevelDetailsDTO | null {
  if (!response) {
    return null;
  }
  return {
    id: response.id,
    title: response[`title_${lang}`],
    color_code: response.color_code,
    type: response.type,
    status: response.status,
  };
}
